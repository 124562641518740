import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Col, Row, Form, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';

import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';

import { updatePatientProfileAction } from 'happ-redux/happ-actions/patient-profile/patientProfileActions';

let initState = {
    _id: '',
    firstName: '',
    lastName: '',
    lastName2: '',
    email: '',
    phone: '',
    gender: '',
    healthForecast: '',
    birthDay: new Date(),
    age: '',
    street: '',
    commune: '',
    pre: '',
    region: '',
    company: '',
    position: '',
    branchOffice: '',
}

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isUpdateProfile } = useSelector(state => state.profile);
    const [updateProfileMessage, setUpdateProfileMessage] = useState({
        show: true,
        text: 'Perfil actualizado con exito',
        type: 'success',
        icon: 'success',
    });
    const {
        state,
        errors,
        comunes,
        stateReg,
        handlerChange,
        handlerChangeBirthDay,
        handlerSubmit,
        handlerChangeNumber,
        handlerChangeReg
    } = useValidatorForm(initState, sendResults, 'profile');
    const {
        _id,
        firstName,
        lastName,
        lastName2,
        email,
        phone,
        gender,
        healthForecast,
        birthDay,
        street,
        commune,
        region,
        company,
        pre,
        // position,
        branchOffice,
    } = state;
    function sendResults() {
        const body = {
            _id,
            firstName,
            lastName: `${lastName} ${lastName2}`,
            fullName: `${firstName} ${lastName} ${lastName2}`, 
            email,
            phone: String(`${pre} ${phone}`),
            gender,
            healthForecast,
            birthDay,
            street,
            commune,
            region,
            company: company._id,
            branchOfficeId: branchOffice._id,
        }
        dispatch(updatePatientProfileAction(body));
    }

    return ( 
        <Form
        onSubmit={handlerSubmit}
    >
        {isUpdateProfile !== null && isUpdateProfile ? (
            <SweetAlert2
                {...updateProfileMessage}
            />
        ) : null}
        {isUpdateProfile !== null && !isUpdateProfile ?(
            <Row className="align-items-center mt-5">
                <p className='error-save'>Hubo un error al actualizar los datos</p>
            </Row>
        ): null}
        <Row className="mb-3">
            <Col sm={3}  className="my-1 col-input">
                <Form.Group  controlId="firstName">
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control 
                        type="text"
                        onChange={handlerChange}
                        value={firstName ? firstName : ''} 
                        name="firstName" 
                        placeholder="Nombres" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.firstName && errors.firstName}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="lastName">
                    <Form.Label>Apellido Paterno</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={lastName ? lastName : ''} 
                        name="lastName" 
                        placeholder="Apellido Paterno" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.lastName && errors.lastName}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="lastName2">
                    <Form.Label>Apellido Materno</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={lastName2 ? lastName2 : ''}  
                        name="lastName2" 
                        placeholder="Apellido Materno" 
                    />
                </Form.Group>
            </Col>
            <Col sm={3} className="my-1 col-input">
                <Form.Group className="mb-3" controlId="birthDay">
                    <Form.Label>Fecha de nacimiento</Form.Label>
                    <Form.Control 
                        type="date"
                        onChange={handlerChangeBirthDay}
                        value={birthDay ? birthDay : ''} 
                        name="birthDay" 
                        placeholder="" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.birthDay && errors.birthDay}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="gender">
                <Form.Label>Genero</Form.Label>
                <Form.Select 
                    name="gender" 
                    onChange={handlerChange}
                    value={gender ? gender : ''} 
                >
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col'>{errors.gender && errors.gender}</p>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={2} className="my-1 col-input">
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Correo Electronico</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={email ? email : ''} 
                        name="email" 
                        placeholder="Correo Electronico" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.email && errors.email}</p>
            </Col>
            <Col sm={3} className="my-1 col-input content-phone">
                <div className='pre-phone'>
                    <Form.Group className="mb-3" controlId="pre">
                        <Form.Label>Codigo</Form.Label>
                        <Form.Control 
                            type="text"
                            onChange={handlerChangeNumber}
                            className=''
                            value={pre ? pre : ''} 
                            name="pre"
                            placeholder='56'
                        />
                    </Form.Group>
                    <p className='p-error-profile-col p-error-pre'>{errors.pre && errors.pre}</p>
                </div>
                <div>
                    <Form.Group className="mb-3 phone" controlId="formGridAddress1">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control 
                            type="text" 
                            className=''
                            onChange={handlerChange}
                            value={phone? phone : ''} 
                            name="phone" 
                            placeholder="Teléfono" 
                        />
                    </Form.Group>
                    <p className='p-error-profile-col p-error-phone'>{errors.phone && errors.phone}</p>
                </div>
                <p className='p-error-profile-col'>{errors.phone && errors.phone}</p>
            </Col>  
            <Col sm={3} className="my-1 col-input">
                <Form.Group className="mb-3" controlId="street">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control 
                        type="text"
                        onChange={handlerChange}
                        value={street? street : ''} 
                        name="street" 
                        placeholder="Dirección" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.street && errors.street}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="region">
                <Form.Label>Región</Form.Label>
                <Form.Select 
                    name="region"
                    onChange={handlerChangeReg}
                    value={region ? region : ''}
                >
                    <option value="sin region">Selecione una región</option>
                    {stateReg && Object.keys(stateReg).length > 0 ? (
                        stateReg.map(reg => (
                            <option key={reg.id} value={reg.name}>{reg.name}</option>
                        ))    
                    ) : null}
                </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col'>{errors.region && errors.region}</p>
            </Col>
                <Col sm={2} className="my-1 col-input">
                    <Form.Group  controlId="commune">
                    <Form.Label>Comuna</Form.Label>
                    <Form.Select 
                        name="commune" 
                        value={commune ? commune : ''}
                        onChange={handlerChange}
                        >
                        <option value="sin comuna">Selecione una comuna</option>
                        {comunes && Object.keys(comunes).length > 0 ? (
                        comunes.map(comuna => (
                            <option key={comuna.id} value={comuna.name}>{comuna.name}</option>

                        ))
                        ) : null}
                    </Form.Select>
                    </Form.Group>
                    <p className='p-error-profile-col'>{errors.commune && errors.commune}</p>
                </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={3} className="my-1 col-input">
                <Form.Group controlId="company">
                    <Form.Label>Empresa</Form.Label>
                    <Form.Select 
                        name="healthForecast" 
                        onChange={handlerChange}
                        disabled
                        value={company ? company._id : ''} 
                    >
                            <option value="">Seleccione empresa</option>
                            <option value={company?._id}>{company?.name}</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col sm={3} className="my-1 col-input">
                <Form.Group controlId="branchOffice">
                    <Form.Label>Sucursal</Form.Label>
                    <Form.Select 
                        name="healthForecast" 
                        onChange={handlerChange}
                        disabled
                        value={branchOffice ? branchOffice._id : ''} 
                    >
                            <option value="">Seleccione sucursal</option>
                            <option value={branchOffice?._id}>{branchOffice?.name}</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col sm={6} className="my-1 col-input">
                <Form.Group  controlId="healthForecast">
                    <Form.Label>Previsión de Salud</Form.Label>
                    <Form.Select 
                        name="healthForecast" 
                        onChange={handlerChange}
                        value={healthForecast !== 'sin prevision' ? healthForecast : ''} 
                    >
                            <option value="">Seleccione una previsión de salud</option>
                            <option value="Fonasa">Fonasa</option>
                            <option value="Isapre Colmena">Isapre Colmena</option>
                            <option value="Isapre Cruz Blanca">Isapre Cruz Blanca</option>
                            <option value="Isapre Banmédica">Isapre Banmédica</option>
                            <option value="Isapre Consalud">Isapre Consalud</option>
                            <option value="Isapre Vida tres">Isapre Vida tres</option>
                            <option value="Isapre Esencial">Isapre Esencial</option>
                            <option value="Isapre Nueva masvida">Isapre Nueva masvida</option>
                    </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col'>{errors.healthForecast && errors.healthForecast}</p>
            </Col>
            {/* <Col sm={3} className="my-1 col-input">
                <Form.Group controlId="position">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={position ? position : ''} 
                        name="position" 
                        placeholder="Cargo" 
                    />
                </Form.Group>
            </Col> */}
        </Row>
        <Row className="align-items-center mt-5">
            <Button 
                variant="primary" 
                type="submit"
            >
                Actualizar
            </Button>
        </Row>
    </Form>
     );
}
 
export default Profile;