import {
    INIT_PATIENT_PROFILE,
    PATIENT_PROFILE,
    ERROR_PATIENT_PROFILE,
    INIT_UPDATE_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PROFILE_ERROR,
    CLEAN_STATUS_PROFILE,
    INIT_GET_COMPANIES,
    GET_COMPANIES,
    ERROR_GET_COMPANIES,
    INIT_GET_BRANCHES,
    GET_BRANCHES,
    ERROR_GET_BRANCHES,
    INIT_CREATE_PATIENT,
    CREATE_PATIENT,
    ERROR_CREATE_PATIENT,
    INIT_GET_BLOODPRESSURE_INFO,
    GET_BLOODPRESSURE_INFO,
    ERROR_GET_BLOODPRESSURE_INFO
} from 'happ-redux/happ-types';

const initState = {
    patient: null,
    error: false,
    errorMsg: null,
    loading: false,
    isUpdateProfile: null,
    currentMedicalStage: null,
    operativeId: null,
    isValidProfile: false,
    isHypertensive: null,
    bloodPressureResults: null,
    idHypertension: null,
    msgBloodPressureTwo: null,
    companies: null,
    branches: null,
    isCreatePatient: null,
    okAddBloodPressureTwo: null,
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action){
    switch(action.type){
        case INIT_PATIENT_PROFILE: 
        case INIT_UPDATE_PROFILE:
        case INIT_GET_COMPANIES:  
        case INIT_GET_BRANCHES:  
        case INIT_GET_BLOODPRESSURE_INFO:
        case INIT_CREATE_PATIENT:
            return {
                ...state,
                loading: true,
            }
        case PATIENT_PROFILE:
            return {
                ...state,
                loading: false,
                patient: action.payload.patient,
                currentMedicalStage: action.payload.currentMedicalStage,
                operativeId: action.payload.operativeId,
                isHypertensive: action.payload.isHypertensive,
                error: false,
                errorMsg: null,
                isValidProfile: true,
                bloodPressureResults: action.payload.bloodPressureResults,
            }
        case GET_BLOODPRESSURE_INFO:
            return {
                ...state,
                loading: false,
                patient: action.payload.patient,
                currentMedicalStage: action.payload.currentMedicalStage,
                operativeId: action.payload.operativeId,
                bloodPressureResults: action.payload.bloodPressureResults,
                idHypertension: action.payload.healthBenefits,
                okAddBloodPressureTwo: true,
                msgBloodPressureTwo: null,
            } 
        case ERROR_GET_BLOODPRESSURE_INFO:
            return {
                ...state,
                loading: false,
                patient: null,
                currentMedicalStage: null,
                operativeId: null,
                bloodPressureResults: null,
                idHypertension: null,
                okAddBloodPressureTwo: false,
                msgBloodPressureTwo: action.payload.msg,
            }        
        case ERROR_PATIENT_PROFILE:
            return {
                ...state,
                loading: false,
                patient: null,
                currentMedicalStage: null,
                isHypertensive: null,
                bloodPressureResults: null,
                error: true,
                operativeId: null,
                errorMsg: action.payload,
                isValidProfile: false,
            } 
        case UPDATE_PROFILE:
            return {
                ...state,
                loading: false,
                isUpdateProfile: true,
                patient: action.payload
            }  
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                isUpdateProfile: false,
            }
        case CLEAN_STATUS_PROFILE:
            return {
                ...state,
                isUpdateProfile: null,
                isCreatePatient: null,
            }  
        case GET_COMPANIES:
            return {
                ...state,
                loading: false,
                companies: action.payload,
            }
        case ERROR_GET_COMPANIES:
            return {
                ...state,
                loading: false,
                companies: null,
            }              

        case GET_BRANCHES:
            return {
                ...state,
                loading: false,
                branches: action.payload,
            }
        case ERROR_GET_BRANCHES:
            return {
                ...state,
                loading: false,
                branches: null,
            }      
        case CREATE_PATIENT:
            return {
                ...state,
                loading: false,
                isCreatePatient: true,
            }
            
        case ERROR_CREATE_PATIENT:
            return {
                ...state,
                loading: false,
                isCreatePatient: false,
            }    
        default:
            return state;
    }
}