import { checkRut } from "react-rut-formatter";

export default function validateFormInputs(inputs){
    
    let errors = {};
    // if( ('email' in inputs) && (inputs.email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) ){
    //     errors.email = 'El email es requerido o ingreso un mail no valido';
    // } 
    if( ('password' in inputs) && (inputs.password === '') ) {
        errors.password = 'La contraseña debe ser con un minimo de 6 digitos';
    }
    if ( ('firstName' in inputs) && inputs.firstName === '' ){
        errors.firstName = 'requerido**'
    }
    if ( ('lastName' in inputs) && inputs.lastName === '' ){
        errors.lastName = 'requerido**'
    }
    if ( ('dni' in inputs) && inputs.dni === ''){
        errors.dni = 'El rut es requerido'
    }
    if ( ('dni' in inputs) && !checkRut(inputs.dni) ){
        errors.dni = 'El Rut no es valido'
    }
    if( ('gender' in inputs) && inputs.gender === '' ) {
        errors.gender = 'requerido**';
    }
    if( ('phone' in inputs) && inputs.phone === '' ) {
        errors.phone = 'requerido**';
    }
    if( ('pre' in inputs) && inputs.pre === '' ) {
        errors.pre = 'requerido**';
    }
    // if( ('phone' in inputs) && inputs.phone.length > 1 && (inputs.phone.length > 9 || inputs.phone.length < 9) ) {
    //     errors.phone = 'Solo 9 digitos';
    // }
    if( ('region' in inputs) && inputs.region === '' ) {
        errors.region = 'requerido**';
    }    
    if( ('commune' in inputs) && inputs.commune === '' ) {
        errors.commune = 'requerido**';
    }
    // if( ('street' in inputs) && inputs.street === '' ) {
    //     errors.street = 'requerido**';
    // }
    if( ('birthDay' in inputs) && (inputs.birthDay === 'Invalid date' || inputs.birthDay === '') ) {
        errors.birthDay = 'requerido**';
    }
    // if( ('healthForecast' in inputs) && inputs.healthForecast === '' ) {
    //     errors.healthForecast = 'requerido**';
    // }
    if( ('company' in inputs) && inputs.company === '' ) {
        errors.company = 'Seleccione una empresa';
    }
    if( ('branchOffice' in inputs) && inputs.branchOffice === '' ) {
        errors.branchOffice = 'Seleccione una sucursal';
    }
    if( ('glycemia' in inputs) && (inputs.glycemia === '' || !/^[0-9]+$/.test(inputs.glycemia)) ) {
        errors.glycemia = 'Solo Número';
    }
    if( ('weight' in inputs) && (inputs.weight === '') ) {
        errors.weight = 'Solo Número';
    }
    if( ('height' in inputs) && (inputs.height === '') ) {
        errors.height = 'Solo Número';
    }
    if( ('imc' in inputs) && (inputs.imc === '' || !/^[0-9]+$/.test(inputs.imc)) ) {
        errors.imc = 'Solo Número';
    }
    if( ('circumference' in inputs) && (inputs.circumference === '' || !/^[0-9]+$/.test(inputs.circumference)) ) {
        errors.circumference = 'Solo Número';
    }
    if( ('colesterol' in inputs) && (inputs.colesterol === '' || !/^[0-9]+$/.test(inputs.colesterol)) ) {
        errors.colesterol = 'Solo Número';
    }
    if( ('trigliceridos' in inputs) && (inputs.trigliceridos === '' || !/^[0-9]+$/.test(inputs.trigliceridos)) ) {
        errors.trigliceridos = 'Solo Número';
    }
    if( ('sistolica' in inputs) && (inputs.sistolica === '' || !/^[0-9]+$/.test(inputs.sistolica)) ) {
        errors.sistolica = 'Solo Números';
    }
    if( ('diastolica' in inputs) && (inputs.diastolica === '' || !/^[0-9]+$/.test(inputs.diastolica)) ) {
        errors.diastolica = 'Solo Números';
    }
    if( ('box' in inputs) && (inputs.box === '') ) {
        errors.box = 'Seleccione un box';
    }
    // if ( ('normalEcg' in inputs) && inputs.normalEcg === -1 ) {
    //     errors.normalEcg = '* Confirme si el ECG es normal o no'
    // }
    // if ( ('observationEcg' in inputs) && inputs.observationEcg === '' ) {
    //     errors.observationEcg = '* Escriba una Observación'
    // }
    if ( ('normalAus' in inputs) && inputs.normalAus === -1 ) {
        errors.normalAus = '* Confirme si la Auscultación es normal o no'
    }
    if ( ('obsCre' in inputs) && inputs.obsCre === -1 ) {
        errors.obsCre = '* Confirme si observa crépitos o no'
    }
    if ( ('observationAus' in inputs) && inputs.observationAus === '' ) {
        errors.observationAus = '* Escriba una Observación'
    }
    if ( ('normalAusPulmon' in inputs) && inputs.normalAusPulmon === -1 ) {
        errors.normalAusPulmon = '* Confirme si la Auscultación es normal o no'
    }
    if ( ('obsCrePul' in inputs) && inputs.obsCrePul === -1 ) {
        errors.obsCrePul = '* Confirme si observa crépitos o no'
    }
    if ( ('observationAusPulmon' in inputs) && inputs.observationAusPulmon === '' ) {
        errors.observationAusPulmon = '* Escriba una Observación'
    }
    if ( ('edemaResult' in inputs) && inputs.edemaResult === -1 ) {
        errors.edemaResult = '* Confirme si hay Edema o no'
    }
    if ( ('observationEdema' in inputs) && inputs.observationEdema === '' ) {
        errors.observationEdema = '* Escriba una Observación'
    }
    // if ( ('grado' in inputs) && inputs.grado === -1 ) {
    //     errors.grado = '* Confirme el grado del Edema'
    // }
    if ( ('file' in inputs) && inputs.file === null ) {
        errors.file = '* Debe seleccionar un documento'
    }
    return errors;
}