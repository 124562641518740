import {
    INIT_GET_HEALTH_BENEFIT_ID,
    GET_HEALTH_BENEFIT_ID,
    ERROR_GET_HEALTH_BENEFIT_ID,
    INIT_SAVE_EXAM_PARAMS,
    SAVE_EXAM_PARAMS,
    ERROR_SAVE_EXAM_PARAMS,
    CLEAN_STATUS_EXAMS,
    INIT_GET_HEALTH_BENEFITS_BY_PATIENT,
    GET_HEALTH_BENEFITS_BY_PATIENT,
    ERROR_GET_HEALTH_BENEFITS_BY_PATIENT,
    INIT_HEALTH_BENEFITS_UPDATE_LIST,
    HEALTH_BENEFITS_UPDATE_LIST,
    ERROR_HEALTH_BENEFITS_UPDATE_LIST,
    INIT_GENERATE_MEDICAL_AGENDA,
    GENERATE_MEDICAL_AGENDA,
    ERROR_GENERATE_MEDICAL_AGENDA,
    INIT_VALIDATE_MEDICAL_AGENDA,    
    VALIDATE_MEDICAL_AGENDA,
    ERROR_VALIDATE_MEDICAL_AGENDA,
    INIT_SAVE_PHYSICAL_EXAM,
    SAVE_PHYSICAL_EXAM,
    ERROR_SAVE_PHYSICAL_EXAM,
    INIT_GET_LAST_PHYSICAL_EXAM,
    GET_LAST_PHYSICAL_EXAM,
    ERROR_GET_LAST_PHYSICAL_EXAM,
    INIT_GET_EXAMS_LIST,
    GET_EXAMS_LIST,
    ERROR_GET_EXAMS_LIST,
} from 'happ-redux/happ-types';
import { 
    getHealthBenefitByCode, 
    saveExamParamsByPatient, 
    getHealthBenefitsByPatient, 
    examsListChecked,
    calculateMedicalAgendaPatient,
    validateMedicalAgendaPatient,
    saveHypertensionExamParamsByPatient,
    saveHypertensionExamParamsByPatientBeta,
    getHypertensionByCode,
    savePhysicalExam,
    getLastPhysicalExam,
    getHealthBenefitsByPatientDni 
} from 'happ-redux/happ-services/exams/exams-services';

export function getHealthBenefitByCodeAction(code, stage, patient, navigate) {
    return async dispatch => {
        dispatch(initHealthBefenitCode());
        try {
            const token = localStorage.getItem('token');
            if (stage === 'dc-1') {
                const response = await getHealthBenefitByCode(token, code);
                if (response.status === 200) {
                    dispatch(healthBenefitCode(response.data));
                } else {
                    dispatch(errorHealthBenefitCode(response.data.message));
                }
            } else {
                const response = await getHypertensionByCode(token, code, patient);
                if (response.status === 200) {
                    dispatch(healthBenefitCode(response.data));
                } else {
                    dispatch(errorHealthBenefitCode(response.data.message));
                }
            }
        } catch (error) {
            dispatch(errorHealthBenefitCode('Hubo un error'));
        }
    }
}

export function getHealthBenefitsByPatientDniAction(dni) {
    return async dispatch => {
        dispatch(initGetHealthBenefitsPatientDni());
        try {
            const token = localStorage.getItem('token');
            const response = await getHealthBenefitsByPatientDni(token, dni);
            console.log(response);
            if (response.status === 200) {
                dispatch(getHealthBenefitsPatientDni(response.data));
            } else {
                dispatch(errorGetHealthBenefitsPatientDni());
            }
        } catch (error) {
            dispatch(errorGetHealthBenefitsPatientDni());
        }
    }
}

export function calculateMedicalAgendaByPatientAction(body) {
    return async dispatch => {
        dispatch(initCalculateMedicalAgenda());
        try {
            const token = localStorage.getItem('token');
            const response = await calculateMedicalAgendaPatient(token, body);
            const { boxEnabled } = response.data;
            if (boxEnabled) {
                dispatch(calculateMedicalAgenda());
            } else {
                dispatch(errorCalculateMedicalAgenda(response.data.message));
                setTimeout(() => {
                    dispatch(cleanStatus());
                }, 2000);
            }
        } catch (error) {
            dispatch(errorCalculateMedicalAgenda('Hubo un error con el servidor'));
            setTimeout(() => {
                dispatch(cleanStatus());
            }, 2000);
        }
    } 
}

export function validateMedicalAgendaPatientAction(body) {
    return async dispatch => {
        dispatch(initValidateAgenda());
        try {
            const token = localStorage.getItem('token');
            const response = await validateMedicalAgendaPatient(token, body);
            const {areDone} = response.data;
            if ( areDone ) {
                dispatch(validateAgenda(true));
            } else {
                dispatch(errorValidateAgenda(false));
            }
        } catch (error) {
            dispatch(errorValidateAgenda(false));
        }
    }
}

export function healthBenefitUpdateListAction(exams) {
    return async dispatch => {
        dispatch(initHealthBenefitsList())
        try {
            const token = localStorage.getItem('token');
            const response = await examsListChecked(token, exams);
            if (response.status === 201) {
                dispatch(healthBenefitsList());
                setTimeout(() => {
                    dispatch(cleanStatus());
                }, 2000);
            } else {
                dispatch(errorHealthBenefitsList());
                setTimeout(() => {
                    dispatch(cleanStatus());
                }, 2000);
            }
        } catch (error) {
            dispatch(errorHealthBenefitsList());
            setTimeout(() => {
                dispatch(cleanStatus());
            }, 2000);
        }
    }
}

export function getHealthBenefitsByPatientAction(navigate, patient, medicalStage) {
    return async dispatch => {
        dispatch(initGetHealthBenefitsPatient());
        try {
            const token = localStorage.getItem('token');
            const response = await getHealthBenefitsByPatient(token, patient, medicalStage);
            if (response.status === 200) {
                dispatch(getHealthBenefitsPatient(response.data));
            } else {
                dispatch(errorGetHealthBenefitsPatient());
            }
        } catch (error) {
            dispatch(errorGetHealthBenefitsPatient());
        }
    }
}

export function saveExamParamsAction(navigate, body, stage) {
    return async dispatch => {
        dispatch(initSaveExamParams());
        try {
            const token = localStorage.getItem('token');
            if (stage === 'dc-1') {
                const response = await saveExamParamsByPatient(token, body);
                if (response.status === 201) {
                    dispatch(saveExamParams())
                    setTimeout(() => {
                        dispatch(cleanStatus());
                      }, 2000);
                } else {
                    dispatch(errorSaveExamParams())
                    setTimeout(() => {
                        dispatch(cleanStatus());
                      }, 2000);
                }
            } else {
                const response = await saveHypertensionExamParamsByPatientBeta(token, body);
                if (response.status === 201) {
                    dispatch(saveExamParams())
                    setTimeout(() => {
                        dispatch(cleanStatus());
                      }, 2000);
                } else {
                    dispatch(errorSaveExamParams())
                    setTimeout(() => {
                        dispatch(cleanStatus());
                      }, 2000);
                }
            }
        } catch (error) {
            dispatch(errorSaveExamParams())
            setTimeout(() => {
                dispatch(cleanStatus());
              }, 2000);
        }
    }
}

export function savePhysicalExamAction(body) {
    return async dispatch => {
        dispatch(initSavePhysicalExam())
        try {
            const token = localStorage.getItem('token');
            const response = await savePhysicalExam(token, body);
            console.log(response);
            if (response.status === 201) {
                dispatch(savePhysicalExamState(response.data));
                setTimeout(() => {
                    dispatch(cleanStatus());
                  }, 2000);
            } else {
                dispatch(errorSavePhysicalExamState());
                setTimeout(() => {
                    dispatch(cleanStatus());
                  }, 2000);
            }
        } catch (error) {
            dispatch(errorSavePhysicalExamState());
            setTimeout(() => {
                dispatch(cleanStatus());
              }, 2000);
        }
    }
}

export function getLastPhysicalExamAction(patient, examName) {
    return async dispatch => {
        dispatch(initGetLastPhysical());
        try {
            const token = localStorage.getItem('token');
            const response = await getLastPhysicalExam(token, patient, examName);
            if (response.data.status === 200) {
                dispatch(getLastPhysical(response.data));
            } else {
                dispatch(errorGetLastPhysical())
            }
        } catch (error) {
            dispatch(errorGetLastPhysical())
        }
    }
}

const initGetLastPhysical = () => ({
    type: INIT_GET_LAST_PHYSICAL_EXAM,
});

const getLastPhysical = response => ({
    type: GET_LAST_PHYSICAL_EXAM,
    payload: response,
});

const errorGetLastPhysical = () => ({
    type: ERROR_GET_LAST_PHYSICAL_EXAM,
});

const initSavePhysicalExam = () => ({
    type: INIT_SAVE_PHYSICAL_EXAM,
});

const savePhysicalExamState = (response) => ({
    type: SAVE_PHYSICAL_EXAM,
    payload: response,
});

const errorSavePhysicalExamState = () => ({
    type: ERROR_SAVE_PHYSICAL_EXAM,
});

const initHealthBefenitCode = () => ({
    type: INIT_GET_HEALTH_BENEFIT_ID
});

const healthBenefitCode = response => ({
    type: GET_HEALTH_BENEFIT_ID,
    payload: response
});

const errorHealthBenefitCode = error => ({
    type: ERROR_GET_HEALTH_BENEFIT_ID,
    payload: error,
});

const initSaveExamParams = () => ({
    type: INIT_SAVE_EXAM_PARAMS
});

const saveExamParams = () => ({
    type: SAVE_EXAM_PARAMS
});

const errorSaveExamParams = () => ({
    type: ERROR_SAVE_EXAM_PARAMS
});

const initGetHealthBenefitsPatientDni = () => ({
    type: INIT_GET_EXAMS_LIST
});

const getHealthBenefitsPatientDni = response => ({
    type: GET_EXAMS_LIST,
    payload: response
});

const errorGetHealthBenefitsPatientDni = () => ({
    type: ERROR_GET_EXAMS_LIST,
});

const cleanStatus = () => ({
    type: CLEAN_STATUS_EXAMS
});

const initGetHealthBenefitsPatient = () => ({
    type: INIT_GET_HEALTH_BENEFITS_BY_PATIENT
});

const getHealthBenefitsPatient = response => ({
    type: GET_HEALTH_BENEFITS_BY_PATIENT,
    payload: response
});

const errorGetHealthBenefitsPatient = () => ({
    type: ERROR_GET_HEALTH_BENEFITS_BY_PATIENT,
});

const initHealthBenefitsList = () => ({
    type: INIT_HEALTH_BENEFITS_UPDATE_LIST
});

const healthBenefitsList = () => ({
    type: HEALTH_BENEFITS_UPDATE_LIST
});

const errorHealthBenefitsList = () => ({
    type: ERROR_HEALTH_BENEFITS_UPDATE_LIST
});

const initCalculateMedicalAgenda = () => ({
    type: INIT_GENERATE_MEDICAL_AGENDA
});

const calculateMedicalAgenda = () => ({
    type: GENERATE_MEDICAL_AGENDA
});

const errorCalculateMedicalAgenda = error => ({
    type: ERROR_GENERATE_MEDICAL_AGENDA,
    payload: error
});

const initValidateAgenda = () => ({
    type: INIT_VALIDATE_MEDICAL_AGENDA
});

const validateAgenda = response => ({
    type: VALIDATE_MEDICAL_AGENDA,
    payload: response
});

const errorValidateAgenda = error => ({
    type: ERROR_VALIDATE_MEDICAL_AGENDA,
    payload: error,
});