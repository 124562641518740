import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validateFormInputs from 'happ-custom-hooks/form-inputs-validator/inputs';
import moment from 'moment';
import { formatRut } from "react-rut-formatter";
import { regiones, communesByReg } from 'happ-redux/happ-services/patient-profile/patient-profile-service';

const useValidatorForm = (initialState, fn, view) => {
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [submit, setSubmit] = useState(false);
    const [comunes, setComunes] = useState(null);
    const [stateReg, setStateReg] = useState(null);
    const [filterBranches, setFilterBranches] = useState(null);
    const {patient, branches} = useSelector(state => state.profile)
    const {survey, region} = state;

    const calculateAge = (birthday) => {
        const ageDifMs = Date.now() - new Date(birthday).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }  

    useEffect(() => {
        if (region) {
            const comunes = communesByReg(region);
            setComunes(comunes);
        } else {
            setComunes(null);
        }
    }, [region])

    useEffect(() => {
        setStateReg(regiones);
    }, []);

    useEffect(() => {
        if (submit) {
            if ( !Object.keys(errors).length ){
                fn();
            }
            setSubmit(false);
        }
    }, [errors, submit]);

    useEffect(() => {
        if (view === 'profile') {
            if (patient) {
                const _id = patient?._id;
                const birthDay = moment.utc(patient?.birthDay).format('YYYY-MM-DD');
                const formatBirthDay = birthDay === '1000-01-01' ? '' : birthDay;
                const age = calculateAge(birthDay);
                const lastName = patient?.lastName.split(' ')[0];
                const lastName2 = patient?.lastName.split(' ')[1] !== undefined ? patient?.lastName.split(' ')[1] : '';
                const phoneSplitLength = patient?.phone.split(' ').length;
                const phone = phoneSplitLength > 1 ?
                    patient?.phone.split(' ')[1] === '987654321' ? '' : patient?.phone.split(' ')[1] : 
                    patient?.phone === '987654321' ? '' : patient?.phone;
                const pre = phoneSplitLength > 1 ?
                    patient?.phone.split(' ')[0] : 
                    '';
                const street = patient?.address[0]?.street === 'sin calle' ? '' : patient?.address[0]?.street;
                const commune = patient?.address[0]?.commune === 'sin comuna' ? '' : patient?.address[0]?.commune;
                const region = patient?.address[0]?.region === 'sin region' ? '' : patient?.address[0]?.region;
                const gender = patient?.gender === 'sin genero' ? '' : patient?.gender;
                setState({
                    _id,
                    firstName: patient?.firstName, 
                    dni: patient?.dni,
                    lastName,
                    lastName2,
                    email: patient?.email,
                    pre,
                    phone,
                    gender,
                    healthForecast: patient?.healthForecast,
                    birthDay: formatBirthDay,
                    age,
                    street,
                    commune,
                    region,
                    company: patient?.company,
                    branchOffice: patient?.branchOfficeId,
                })
            }
        }
    }, [view, patient])
    

    useEffect(() => {
      if (view === 'survey'){
        setState({
            ...state,
            survey: [
                {
                    item: '¿Usted tiene diabetes diagnosticada?',
                    value: false,
                    code: 'rcv_q_01',
                    riskCriteria: false
                },
                {
                    item: '¿Usted consume medicamentos para la diabetes?',
                    value: false,
                    code: 'rcv_q_02',
                    riskCriteria: false
                },
                {
                    item: '¿Usted consume medicamentos para el colesterol o triglicéridos?',
                    value: false,
                    code: 'rcv_q_03',
                    riskCriteria: true
                },
                {
                    item: '¿Usted tiene hipertensión diagnosticada?',
                    value: false,
                    code: 'rcv_q_04',
                    riskCriteria: true
                },
                {
                    item: '¿Usted consume medicamentos para la presión arterial?',
                    value: false,
                    code: 'rcv_q_05',
                    riskCriteria: false
                },
                {
                    item: '¿Usted ha fumado el último año?',
                    value: false,
                    code: 'rcv_q_06',
                    riskCriteria: false
                },
                {
                    item: '¿Usted ha tenido un infarto al corazón o infarto cerebral?',
                    value: false,
                    code: 'rcv_q_07',
                    riskCriteria: true
                },
                {
                    item: '¿Su padre tuvo un infarto al corazón o infarto cerebral antes de los 65 años o madre antes de los 55 años?',
                    value: false,
                    code: 'rcv_q_08',
                    riskCriteria: true
                }
            ]
        })
      }
    }, [view]);
    
    const handlerChangeSurvey = e => {
        const updateSurvey = survey.map(question => {
            if (question.code === e.target.name) {
                question.value = e.target.checked;
            }
            return question;
        })
        setState({
            ...state,
            survey: updateSurvey
        });
    }

    const resetSurvey = e => {
        setState({
            ...state,
            survey: [
                {
                    item: '¿Usted tiene diabetes diagnosticada?',
                    value: false,
                    code: 'rcv_q_01',
                    riskCriteria: false
                },
                {
                    item: '¿Usted consume medicamentos para la diabetes?',
                    value: false,
                    code: 'rcv_q_02',
                    riskCriteria: false
                },
                {
                    item: '¿Usted consume medicamentos para el colesterol o triglicéridos?',
                    value: false,
                    code: 'rcv_q_03',
                    riskCriteria: true
                },
                {
                    item: '¿Usted tiene hipertensión diagnosticada?',
                    value: false,
                    code: 'rcv_q_04',
                    riskCriteria: true
                },
                {
                    item: '¿Usted consume medicamentos para la presión arterial?',
                    value: false,
                    code: 'rcv_q_05',
                    riskCriteria: false
                },
                {
                    item: '¿Usted ha fumado el último año?',
                    value: false,
                    code: 'rcv_q_06',
                    riskCriteria: false
                },
                {
                    item: '¿Usted ha tenido un infarto al corazón o infarto cerebral?',
                    value: false,
                    code: 'rcv_q_07',
                    riskCriteria: true
                },
                {
                    item: '¿Su padre tuvo un infarto al corazón o infarto cerebral antes de los 65 años o madre antes de los 55 años?',
                    value: false,
                    code: 'rcv_q_08',
                    riskCriteria: true
                }
            ]
        })
    }

    const handlerChangeDni = e => {
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setState({
            ...state,
            dni: formatted
        });
    }

    const handlerChangeBirthDay = e => {
        const formatBirthDay = moment.utc(e.target.value).format('YYYY-MM-DD');
        setState({
            ...state,
            birthDay: formatBirthDay
        })
    } 

    const handleChangeFile = e => {
        const fileUploaded = e.target.files[0];
        setState({
            ...state,
            file: fileUploaded
        });
    };

    const handlerChangeNumber = e => {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        console.log(onlyContainsNumbers(e.target.value));
        if (onlyContainsNumbers(e.target.value) || e.target.value === '') {
            setState({
                ...state,
                pre: e.target.value,
            });
        }
    }

    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const handlerChangeCompany = e => {
        setState({
            ...state,
            company: e,
        });
        const branchesByCompany = branches.filter(item => item.company._id === e);
        setFilterBranches(branchesByCompany);
    }

    const handlerSubmit = e => {
        e.preventDefault();
        const arrayErrors = validateFormInputs(state);
        setErrors(arrayErrors);
        setSubmit(true);
    }

    const handlerChangeReg = e => {
        const comunes = communesByReg(e.target.value);
        setComunes(comunes);
        setState({
            ...state,
            region: e.target.value, 
            commune: ''
        });     
    }

    return {
        state,
        errors,
        comunes,
        stateReg,
        filterBranches,
        handlerChange,
        handlerChangeSurvey,
        handlerChangeBirthDay,
        handlerChangeDni,
        handlerChangeCompany,
        resetSurvey,
        handlerSubmit,
        handlerChangeReg,
        handleChangeFile,
        handlerChangeNumber
    }
}
 
export default useValidatorForm;