import {
    INIT_PATIENT_PROFILE,
    PATIENT_PROFILE,
    ERROR_PATIENT_PROFILE,
    INIT_UPDATE_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PROFILE_ERROR,
    CLEAN_STATUS_PROFILE,
    INIT_GET_COMPANIES,
    GET_COMPANIES,
    ERROR_GET_COMPANIES,
    INIT_GET_BRANCHES,
    GET_BRANCHES,
    ERROR_GET_BRANCHES,
    INIT_CREATE_PATIENT,
    CREATE_PATIENT,
    ERROR_CREATE_PATIENT,
    INIT_GET_BLOODPRESSURE_INFO,
    GET_BLOODPRESSURE_INFO,
    ERROR_GET_BLOODPRESSURE_INFO
} from 'happ-redux/happ-types';

import { getPatientByDni, updatePatientProfile, getAllCompanies, getAllBranches, createPatient } from 'happ-redux/happ-services/patient-profile/patient-profile-service';
import { getBloodPressureInfoPatient } from 'happ-redux/happ-services/exams/exams-services'; 

export function getPatientByDniAction(dni, navigate) {
    return async dispatch => {
        dispatch(initPatientProfile());
        try {
            const token = localStorage.getItem('token');
            const response = await getPatientByDni(token, dni);
            if (response.status === 200) {
                dispatch(patientProfile(response.data));
            } else {
                dispatch(errorPatientProfile(response.data.message));
            }
        } catch (error) {
            dispatch(errorPatientProfile('El rut no existe'));
        }
    }
}

export function createPatientAction(body) {
    return async dispatch => {
        dispatch(initCreatePatientProfile());
        try {
            const token = localStorage.getItem('token');
            const response = await createPatient(token, body);
            if (response.status === 201) {
                dispatch(createpatientProfile(response.data));
                setTimeout(() => {
                    dispatch(cleanStatus());
                  }, 2000);
            } else {
                dispatch(errorCreatePatientProfile(response.data.message));
                setTimeout(() => {
                    dispatch(cleanStatus());
                  }, 2000);
            }
        } catch (error) {
            dispatch(errorCreatePatientProfile('El rut no existe'));
            setTimeout(() => {
                dispatch(cleanStatus());
              }, 2000);
        }
    }
}

export function getCompaniesAction() {
    return async dispatch => {
        dispatch(initGetCompanies());
        try {
            const token = localStorage.getItem('token');
            const response = await getAllCompanies(token);
            if (response.status === 200) {
                dispatch(GetCompanies(response.data));
            } else {
                dispatch(errorGetCompanies(response.data.message));
            }
        } catch (error) {
            dispatch(errorGetCompanies('No hay empresas disponibles'));
        }
    }
}

export function getBranchesAction() {
    return async dispatch => {
        dispatch(initGetBranches());
        try {
            const token = localStorage.getItem('token');
            const response = await getAllBranches(token);
            if (response.status === 200) {
                dispatch(GetBranches(response.data));
            } else {
                dispatch(errorGetBranches(response.data.message));
            }
        } catch (error) {
            dispatch(errorGetBranches('No hay sucursales disponibles'));
        }
    }
}

export function updatePatientProfileAction(patient, navigate) {
    return async dispatch => {
        dispatch(initUpdateProfile())
        try {
            const token = localStorage.getItem('token');
            const _id = patient._id;
            const response = await updatePatientProfile(_id, patient, token);
            console.log(response);
            if (response.status === 200) {
                dispatch(updateProfile(response.data));
                setTimeout(() => {
                    dispatch(cleanStatus());
                  }, 2000);
            } else {
                dispatch(updateProfileError(false));
                setTimeout(() => {
                    dispatch(cleanStatus());
                }, 2000);
            }
        } catch (error) {
            dispatch(updateProfileError(false));
            setTimeout(() => {
                dispatch(cleanStatus());
            }, 2000);
        }
    }
}

export function getBloodPressureInfoPatientAction(dni){
        return async dispatch => {
            dispatch(initGetBloodPressureInfo());
            try {
                const token = localStorage.getItem('token');
                const response = await getBloodPressureInfoPatient(token, dni);
                console.log(response);
                if (response.data.status === 200) {
                    dispatch(getBloodPressureInfo(response.data));
                } else {
                    dispatch(errorGetBloodPressureInfo(response.data));
                }
            } catch (error) {
                dispatch(errorGetBloodPressureInfo());
            }
        }
};

const initGetBloodPressureInfo = () => ({
    type: INIT_GET_BLOODPRESSURE_INFO,
});

const getBloodPressureInfo = (response) => ({
    type: GET_BLOODPRESSURE_INFO,
    payload: response,
});

const errorGetBloodPressureInfo = (error) => ({
    type: ERROR_GET_BLOODPRESSURE_INFO,
    payload: error,
});


const initCreatePatientProfile = () => ({
    type: INIT_CREATE_PATIENT,
})
const createpatientProfile = (response) => ({
    type: CREATE_PATIENT,
    payload: response,
})
const errorCreatePatientProfile = () => ({
    type: ERROR_CREATE_PATIENT,
})

const initGetCompanies = () => ({
    type: INIT_GET_COMPANIES
})
const GetCompanies = (response) => ({
    type: GET_COMPANIES,
    payload: response
})
const errorGetCompanies = (error) => ({
    type: ERROR_GET_COMPANIES,
    payload: error,
})

const initGetBranches = () => ({
    type: INIT_GET_BRANCHES
})
const GetBranches = (response) => ({
    type: GET_BRANCHES,
    payload: response
})
const errorGetBranches = (error) => ({
    type: ERROR_GET_BRANCHES,
    payload: error,
})

const cleanStatus = () => ({
    type: CLEAN_STATUS_PROFILE
});


const initPatientProfile = () => ({
    type: INIT_PATIENT_PROFILE
});

const patientProfile = response => ({
    type: PATIENT_PROFILE,
    payload: response
});

const errorPatientProfile = error => ({
    type: ERROR_PATIENT_PROFILE,
    payload: error
});

const initUpdateProfile = () => ({
    type: INIT_UPDATE_PROFILE
});

const updateProfile = response => ({
    type: UPDATE_PROFILE,
    payload: response
});

const updateProfileError = error => ({
    type: UPDATE_PROFILE_ERROR,
    payload: error
});