import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Col, Row, Form, Button} from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getHealthBenefitByCodeAction, saveExamParamsAction } from 'happ-redux/happ-actions/exams/examsActions';

const initState = {
    weight: '',
    height: '',
    sportsman: false,
    circumference: ''
}

const AntropometriaForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerChange,
        handlerSubmit
    } = useValidatorForm(initState, sendResults, 'antropometria');
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    useEffect(() => {
        dispatch(getHealthBenefitByCodeAction('imc', 'dc-1', navigate))
    }, [])
    
    const {weight, height, sportsman, circumference} = state;
    const { patient, currentMedicalStage, operativeId } = useSelector(state => state.profile);
    const { healthBenefit, errorHealthBenefit, saveParams } = useSelector(state => state.exams);
    const {loginProfile} = useSelector(state => state.auth);
    function sendResults() {
        const body = {
            patient: patient._id,
            healthBenefits: healthBenefit._id,
            status: true,
            admin: loginProfile._id,
            operative: operativeId,
            medicalStage: currentMedicalStage._id,
            origin: 'Happ',
            algorithmName: 'obesity',
            params: {
                weight,
                height,
                sportsman,
                circumference
            }

        }
        dispatch(saveExamParamsAction(navigate, body, 'dc-1'));
        state.weight = '';
        state.height = '';
        state.sportsman = false;
        state.circumference = '';
    }
    return (
        <>
        {saveParams !== null && saveParams ? (
            <SweetAlert2
                {...examMessage}
            />
        ) : null} 
        {saveParams !== null && !saveParams ? (
            <Row className="align-items-center mt-5">
                <p className='success-save'>No se pudo registrar el resultado</p>
            </Row>
        ) : null} 
        <Row className="justify-content-md-center">
            <Col md={7} sm={7} lg={7} className="content-form">
                <Form
                    onSubmit={handlerSubmit}
                >
                    <Row>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Peso</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="weight" 
                                placeholder="Peso"
                                onChange={handlerChange}
                                value={weight ? weight :''} 
                            />
                            {errors && errors.weight ? (
                                <p>{errors.weight}</p>
                            ) : null}
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Altura (cm)</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="height" 
                                placeholder="Altura (Cm)"
                                onChange={handlerChange}
                                value={height ? height : ''} 
                            />
                            {errors && errors.height ? (
                                <p>{errors.height}</p>
                            ) : null}                            
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Check 
                                type="switch"
                                className='switch-imc'
                                id="custom-switch"
                                name="sportsman"
                                onChange={handlerChange}
                                checked={sportsman}
                                label="¿Es deportista?"
                            />                          
                        </Col>
                    </Row>
                    <Row className='mt-3 justify-content-md-center'>
                        <Col md={6} sm={6} lg={6}>
                            <Form.Label>Circunferencia Abdominal</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="circumference" 
                                onChange={handlerChange}
                                placeholder="Circunferencia Abdominal"
                                value={circumference ? circumference : ''} 
                            />
                            {errors && errors.circumference ? (
                                <p>{errors.circumference}</p>
                            ) : null}     
                        </Col>
                    </Row>
                    <Col md={12} sm={12} lg={12} className="mt-3 text-center">
                        <Button
                            type='submit'
                            className='btn btn-primary'
                        >Guardar</Button>
                    </Col>
                </Form>
            </Col>
        </Row>
        </>
    );
}
 
export default AntropometriaForm;