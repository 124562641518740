import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {BoxArrowInRight, MdBloodtype} from 'react-bootstrap-icons';
import './patientOptionsMenu.css';

//components
import { IconCalendar, IconUser, Icontest, IconFicha, IconExamenes, IconGoals, IconIndicators} from 'happ-icons/icons';

const PatientOptionsMenu = ({showContent, cleanSession}) => {
    const [hypertensionOption, setHypertensionOption] = useState(false);
    const [physicalExamsStage, setPhysicalExamsStage] = useState(false);
    const {loginProfile} = useSelector(state => state.auth);
    const {currentMedicalStage, isHypertensive} = useSelector(state => state.profile);
    useEffect(() => {
        if (
            isHypertensive !== null &&
            isHypertensive === true
        ) {
            setHypertensionOption(true);
        }
        if (
            currentMedicalStage && 
            currentMedicalStage?.code === 'physical-exams'
        ) {
            setPhysicalExamsStage(true);
        }
    }, []);
    return ( 
        <>
        <Container className='container-menu-options'>
            <Row className="justify-content-md-center">
                {loginProfile && loginProfile?.role === 2 ? (
                    <>
                        <Col
                            onClick={showContent('profile')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconUser />
                            </div>
                            <h5>Datos</h5>
                        </Col>
                        {hypertensionOption ? (
                            <>
                                <Col
                                    onClick={showContent('bloodPressure')} 
                                    xs 
                                    lg="1" 
                                    className="text-center col-menu-option"
                                >
                                    <div className="content-icon">
                                        <IconFicha />
                                    </div>
                                    <h5>Toma de presión</h5>
                                </Col>
                            </>
                        ) : null}
                        <Col
                            onClick={showContent('box')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconGoals />
                            </div>
                            <h5>Asignación Box</h5>
                        </Col>
                    </>
                ) : null}
                {loginProfile && loginProfile?.role === 3 ? (
                    physicalExamsStage ? (
                        <>
                            <Col
                                onClick={showContent('profile')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconUser />
                                </div>
                                <h5>Datos</h5>
                            </Col>
                            <Col
                                onClick={showContent('formEdema')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconExamenes />
                                </div>
                                <h5>Edema</h5>
                            </Col>
                            <Col
                                onClick={showContent('formEcg')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconExamenes />
                                </div>
                                <h5>ECG</h5>
                            </Col>
                            <Col
                                onClick={showContent('formAuscultacion')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconExamenes />
                                </div>
                                <h5>Auscultación</h5>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col
                                onClick={showContent('profile')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconUser />
                                </div>
                                <h5>Datos</h5>
                            </Col>
                            <Col
                                onClick={showContent('survey')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconExamenes />
                                </div>
                                <h5>Encuestas</h5>
                            </Col>
                            <Col
                                onClick={showContent('bloodPressure')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconFicha />
                                </div>
                                <h5>Toma de presión</h5>
                            </Col>
                            <Col
                                onClick={showContent('anthropromesia')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <Icontest />
                                </div>
                                <h5>Antropometia</h5>
                            </Col>

                            <Col
                                onClick={showContent('hemoglucotest')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconGoals />
                                </div>
                                <h5>Hemoglucotest</h5>
                            </Col>
                            <Col
                                onClick={showContent('box')} 
                                xs 
                                lg="1" 
                                className="text-center col-menu-option"
                            >
                                <div className="content-icon">
                                    <IconGoals />
                                </div>
                                <h5>Asignación Box</h5>
                            </Col>
                        </>
                    )
                ) : null}
                {loginProfile && loginProfile?.role === 4 ? (
                    <>
                        <Col
                            onClick={showContent('profile')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconUser />
                            </div>
                            <h5>Datos</h5>
                        </Col>
                        <Col
                            onClick={showContent('laboratoryExams')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconGoals />
                            </div>
                            <h5>Examenes</h5>
                        </Col>
                    </>
                ) : null}
                {loginProfile && loginProfile?.role === 8 ? (
                    <>
                        <Col
                            onClick={showContent('profile')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconUser />
                            </div>
                            <h5>Datos</h5>
                        </Col>
                        <Col
                            onClick={showContent('survey')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconExamenes />
                            </div>
                            <h5>Encuestas</h5>
                        </Col>
                        <Col
                            onClick={showContent('bloodPressure')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconFicha />
                            </div>
                            <h5>Toma de presión</h5>
                        </Col>
                        <Col
                            onClick={showContent('anthropromesia')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <Icontest />
                            </div>
                            <h5>Antropometia</h5>
                        </Col>

                        <Col
                            onClick={showContent('hemoglucotest')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconGoals />
                            </div>
                            <h5>Hemoglucotest</h5>
                        </Col>
                        <Col
                            onClick={showContent('box')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconGoals />
                            </div>
                            <h5>Asignación Box</h5>
                        </Col>
                        <Col
                            onClick={showContent('laboratoryExams')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconGoals />
                            </div>
                            <h5>Examenes Labs</h5>
                        </Col>
                        {/* <Col
                            onClick={showContent('bloodPressureTwo')} 
                            xs 
                            lg="1" 
                            className="text-center col-menu-option"
                        >
                            <div className="content-icon">
                                <IconFicha />
                            </div>
                            <h5>2da Toma de presión</h5>
                        </Col> */}
                    </>
                ) : null}
            </Row>
            <Button 
                className='btn-clean-session'
                onClick={cleanSession}
            >
                <BoxArrowInRight 
                    size={24}
                    color="white"
                />
            </Button>
        </Container>    
        </>
    );
}
 
export default PatientOptionsMenu;